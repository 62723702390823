import React, { Suspense, lazy } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import WelcomeScreen from '../components/WelcomeScreen';

// Protected Screens
import ProtectedRoute from './protectedRoute';
const Home = lazy(() => import('../screens/Home'));
const OtherTrends = lazy(() => import('../screens/OtherTrends'));
const Fashion = lazy(() => import('../screens/Fashion'));
const HomeDecor = lazy(() => import('../screens/HomeDecor'));
const AboutUs = lazy(() => import('../screens/AboutUs'));
const Subcategories = lazy(() => import('../screens/Subcategories'));
const Categories = lazy(() => import('../screens/Categories'));
const Blogs = lazy(() => import('../screens/Blogs'));
const AllBlogs = lazy(() => import('../screens/AllBlogs'));
const BlogDetails = lazy(() => import('../screens/BlogDetails'));
const Terms = lazy(() => import('../screens/Terms'));
const PrivacyPolicy = lazy(() => import('../screens/PrivacyPolicy'));
const Search = lazy(() => import('../screens/Search'));

const routes = () => {
  return (
    <>
      <Suspense fallback={<WelcomeScreen />}>
        <HashRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/other-trends' element={<OtherTrends />} />
              <Route exact path='/subcategories/:id' element={<Subcategories />} />
              <Route exact path='/categories/:id' element={<Categories />} />
              <Route exact path='/blogs/:id' element={<Blogs />} />
              <Route exact path='/blog-list' element={<AllBlogs />} />
              <Route exact path='/blog-detail/:id' element={<BlogDetails />} />
              <Route exact path='/fashion' element={<Fashion />} /> 
              <Route exact path='/home-decor' element={<HomeDecor />} />
              <Route exact path='/about-us' element={<AboutUs />} />
              <Route exact path='/terms' element={<Terms />} />
              <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route exact path='/search' element={<Search />} />
            </Route>
          </Routes>
        </HashRouter>
      </Suspense>
    </>
  )
}

export default routes;
