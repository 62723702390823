//persisit work
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import thunk from 'redux-thunk';
import root from "../redux/reducer/index";
import { composeWithDevTools } from "redux-devtools-extension";
const middleware = [thunk];

// Set up configuration for persistence
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['categoryTrends']
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, root);

// Create the store
const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(...middleware)
  )
);

// Create the persistor
const persistor = persistStore(store);
export { store, persistor };