import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ProtectedRoute = () => {

  return (
    <>
      {/* {admin_token?  */}
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
        {/* : */}
        {/* navigateToDashboard() */}
      {/* } */}
    </>
  );
};

export default ProtectedRoute;
