import { SET_CATEGORY_TRENDS } from '../type';

const INITIAL_STATE = {
    admin_data: null,
    admin_token: null,
    categoryTrends: [], // Add this line
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CATEGORY_TRENDS:
            return {
                ...state,
                categoryTrends: action.payload,
            };
        // Add other cases if needed
        default:
            return state;
    }
};
