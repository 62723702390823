import React, { useEffect, useState } from 'react';
import { Divider, Col, Row, Input, Menu, Dropdown, Button } from 'antd';
import logo from '../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFetchApi } from '../redux/action/common';

const { Search } = Input;

const Header = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState('mail');
  const [menuTrends, setMenuTrends] = useState([]);
  const [searchType, setSearchType] = useState('blog'); // Default search type
  const navigate = useNavigate();

  const staticItems = [
    {
      label: 'ABOUT US',
      key: 'about-us',
    },
    {
      label: 'BLOGS',
      key: 'blog-list',
    },
    {
      label: 'OTHER TRENDS',
      key: 'other-trends',
    },
    {
      label: 'CONTACT US',
      key: 'contact-us',
    },
  ];

  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === 'other-trends' || e.key === 'about-us' || e.key === 'blog-list') {
      navigate(e.key);
    } else if (e.key === 'contact-us') {
      navigate('about-us/?contact');
    } else {
      navigate('/subcategories/' + e.key);
      window.location.reload();
    }
  };

  const menuTrendsList = () => {
    setLoading(true);
    const apiCallBack = (res) => {
      setLoading(false);
      if (res?.status) {
        const dynamicItems = res.data.map((trend) => ({
          label: trend.category_name.toUpperCase(),
          key: trend._id.toLowerCase().replace(/ /g, '-'),
        }));
        setMenuTrends(dynamicItems);
      } else {
        setMenuTrends([]);
      }
    };
    const url = 'common/home_trends';
    const method = 'GET';
    let shouldDispatch = true;
    let dispatchType = 'SET_CATEGORY_TRENDS';
    dispatch(getFetchApi({ url, method, apiCallBack, shouldDispatch, dispatchType }));
  };

  useEffect(() => {
    menuTrendsList();
  }, []);

  const handleSearchTypeChange = (e) => {
    setSearchType(e.key);
  };

  const onSearch = (value) => {
    if (value) {
      navigate(`/search?t=${encodeURIComponent(value)}&v=${searchType}`);
      window.location.reload();
    }
  };

  const searchTypeMenu = (
    <Menu onClick={handleSearchTypeChange} selectedKeys={[searchType]}>
      <Menu.Item key="blog">Blog</Menu.Item>
      <Menu.Item key="category">Category</Menu.Item>
      <Menu.Item key="subcategory">Subcategory</Menu.Item>
    </Menu>
  );

  const menuItems = [...staticItems.slice(0, 2), ...menuTrends, ...staticItems.slice(2)];

  return (
    <>
      <div className="container">
        <div className="pt-4">
          <Row justify="space-between" align="middle" className="text-center">
            <Col lg={8} md={0} xs={24} />
            <Col lg={8} md={12} xs={24} className="text-center">
              <a href="#">
                <img src={logo} className="logo" alt="Logo" style={{ maxHeight: '100px' }} />
                <h5>HOME</h5>
              </a>
            </Col>
            <Col lg={8} md={12} xs={24} className="text-end d-flex">
              <Dropdown overlay={searchTypeMenu} trigger={['click']}>
                <Button style={{ height: "45px", borderRadius: "4px" }}>{searchType.charAt(0).toUpperCase() + searchType.slice(1)}</Button>
              </Dropdown>
              <Search
                placeholder="Search"
                enterButton
                className="search-box"
                onSearch={onSearch}
                style={{ maxWidth: '100%', marginLeft: '8px' }} // Adjusted style for layout
              />
            </Col>
          </Row>
        </div>
      </div>
      <Divider />
      <div className="ant-menu">
        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menuItems} className="align-middle" />
      </div>
    </>
  );
};

export default Header;
