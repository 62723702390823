import { baseUrl } from "../../Constant/url";
import { apiCall } from "../../api";
import { store } from "../../store/store";
export const SET_CATEGORY_TRENDS = 'SET_CATEGORY_TRENDS';

export const getFetchApi = ({ url, method = 'POST', data, hasFileUpload = false, apiCallBack, shouldDispatch = false, dispatchType = null, secure = true }) => async (dispatch) => {
   
    const rockAdminToken = store.getState()?.common?.admin_token;
    let config = {
        method: method,
        url: `${baseUrl}${url}`,
    };
        if (data) {
        config.data = data
    }    
    if (secure) {
        config.headers = {
            Authorization: `Bearer ${rockAdminToken}`,
            "Content-Type": hasFileUpload ? "multipart/form-data" : "application/json",
            // 'x-csrf-token': csrfToken
        }
    } else {
        config.headers = { "Content-Type": hasFileUpload ? "multipart/form-data" : "application/json" }
    };

    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            if (shouldDispatch) {
                dispatch({ type: dispatchType, payload: response?.data })
            }
            apiCallBack(response?.data);
        } else {
            apiCallBack(response?.data);
        }
    } catch (error) {
        apiCallBack(response?.data);
    }
};

