import React, { useEffect, useState } from 'react';
import { Col, Row, Menu } from "antd";
import logo from '../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFetchApi } from '../redux/action/common';
import insta from '../assets/icons/instagram.png';
import youtube from '../assets/icons/youtube.png';
import pint from '../assets/icons/pinterest.png';

const Footer = () => {
  const [current, setCurrent] = useState('mail');
  const [menuTrends, setMenuTrends] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const staticItems = [
    {
      label: 'ABOUT US',
      key: 'about-us',
    },
    {
      label: 'BLOGS',
      key: 'blog-list',
    },
    {
      label: 'OTHER TRENDS',
      key: 'other-trends',
    },
    {
      label: 'CONTACT US',
      key: 'contact-us',
    },
  ];


  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === 'other-trends' || e.key === 'about-us' || e.key === 'blog-list') {
      navigate(e.key);
    } else if (e.key === 'contact-us') {
      navigate('about-us/?contact');
    } else {
      navigate('/subcategories/' + e.key);
      window.location.reload();
    }
  };

  const menuTrendsList = () => {
    const apiCallBack = (res) => {
      if (res?.status) {
        const dynamicItems = res.data.map((trend) => ({
          label: trend.category_name.toUpperCase(),
          key: trend._id.toLowerCase().replace(/ /g, '-'),
        }));
        setMenuTrends(dynamicItems);
      } else {
        setMenuTrends([]);
      }
    };
    const url = 'common/home_trends';
    const method = 'GET';
    let shouldDispatch = true;
    let dispatchType = 'SET_CATEGORY_TRENDS';
    dispatch(getFetchApi({ url, method, apiCallBack, shouldDispatch, dispatchType }));
  };

  useEffect(() => {
    menuTrendsList();
  }, []);

  const menuItems = [...staticItems.slice(0, 2), ...menuTrends, ...staticItems.slice(2)];

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="card-bg footer pt-5 m-pt-2">
        <div className="pt-4">
          <Row justify="space-between" align="middle" className="text-center">
            <Col lg={4} md={4} xs={24} />
            <Col lg={14} md={14} xs={24} className="text-center mb-4">
              <a href="#">
                <img src={logo} className="logo" alt="Logo" style={{ maxHeight: '100px' }} />
                <h5>HOME</h5>
              </a>
            </Col>
            <Col lg={6} md={6} xs={24} className="text-center">
              <a href="https://www.pinterest.com.au/primepicksbypreeti/" target="_blank" className='me-3' rel="noopener noreferrer">
                <img src={pint} alt="Pinterest" style={{ width: '28px' }} />
              </a>
              <a href="https://youtube.com/@itsprimepicks21/" target="_blank" className='me-3' rel="noopener noreferrer">
                <img src={youtube} alt="YouTube" style={{ width: '28px' }} />
              </a>
              {/* <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="Instagram" style={{ width: '28px' }} />
              </a> */}
            </Col>
          </Row>
        </div>
        <div className="ant-menu m-pb-0">
          <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menuItems} className="align-middle" />
        </div>
      </div>

      <div className="footer_div">
        <span className='medium_font' style={{ color: "#212121", fontWeight: "500", fontSize: "14px" }}>
          COPYRIGHT &copy; {currentYear} IT’S PRIME PICKS - LEGAL - DISCLAIMER - <a href='#/terms'>TERMS</a> - <a href='#/privacy-policy'>PRIVACY POLICY</a>
        </span>
      </div>
    </>
  );
};

export default Footer;
