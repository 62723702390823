import React from 'react';

const WelcomeScreen = () => {
  return (
    <>
      <div className="welcome_div">
       
      </div>
    </>
  )
}

export default WelcomeScreen
